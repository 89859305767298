import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      country: 'country'
    })
  },

  methods: {
    async getLocation () {
      if (this.$geolocation.checkSupport()) {
        await this.$geolocation.getCurrentPosition()
          .then((location) => {
            this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/location?latitude=${location.coords.latitude}&longitude=${location.coords.longitude}`).then((location) => {
              this.$store.commit('SET_LOCATION', location.data)
            })
          })
          .catch((error) => {
            console.log(error)
            // this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/location`)
            //   .then((location) => {
            //     this.$store.commit('SET_LOCATION', location.data)
            //   })
          })
      }
    },

    async getDeliveryOptions (product, city) {
      await this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/product/${product.id}/cities/${city.id}`, { progress: false })
        .then((location) => {
          this.$store.commit('SET_LOCAL_DELIVERY_OPTIONS', location.data.deliveryOptions)
          this.$store.commit('SET_LOCAL_PAYMENT_OPTIONS', location.data.paymentOptions)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    setCity (city) {
      this.$store.commit('SET_CITY', city)
    }
  }
}
